import axios  from 'axios'

import { labelsStore, languagesStore } from 'sdc-react-i18n'
import { makeCmsLabelsStore, makeCmsLanguagesStore } from 'sdc-cms-i18n'

const cmsBase           = 'https://www.headless-cms.io/api/'
const backend = axios.create({
  withCredentials : true,
  baseURL         : cmsBase,
})

const cmsLanguagesStore = makeCmsLanguagesStore({backend, spaceID : 'fIXfrI2fWcKpFw1NbGmNF4QoK0tUS7hc'})
const cmsLabelsStore    = makeCmsLabelsStore(   {backend, typeID  : 'OeBFGPsnCmLn1S8cXTPZFNExYmzp6qhU'})

export {
  labelsStore,
  languagesStore,
}
