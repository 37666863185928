import React from 'react'
import { Root, Routes } from 'react-static'
import { Link } from '@reach/router'

import { Provider } from 'mobx-react'

import * as stores from './stores'

import './app.css'

function App() {
  return <Provider {...stores}>
    <Root>
      <nav>
        <Link to="/">Start</Link>
        <Link to="/about">Über uns</Link>
        <Link to="/blog">Blog</Link>
        <Link to="/echaton">Echaton</Link>
        <Link to="/ritchy">Ritchy</Link>
        <Link to="/sudoku">Sudoku</Link>
        <Link to="/mahjongg">Mahjongg</Link>
      </nav>
      <div className="content">
        <Routes />
      </div>
    </Root>
  </Provider>
}

export default App
